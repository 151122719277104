// @mui
import { Grid, Typography } from '@mui/material'
// components
import NextImage from '~/components/NextImage'
import { Section } from '~/components/Section'

// images
import logoAstra from '~/assets/home/company/astra.png'
import logoDentsu from '~/assets/home/company/dentsu.png'
import logoEfishery from '~/assets/home/company/efishery.png'
import logoGojek from '~/assets/home/company/gojek.png'
import logoHalodoc from '~/assets/home/company/halodoc.png'
import logoIdnTimes from '~/assets/home/company/idn-times.png'
import logoKitabisa from '~/assets/home/company/kitabisa.png'
import logoShopee from '~/assets/home/company/shopee.png'

// ----------------------------------------------------------------------

const COMPANY_LOGO = Object.freeze([
  {
    name: 'Gojek',
    logo: logoGojek
  },
  {
    name: 'Shopee',
    logo: logoShopee
  },
  {
    name: 'Astra International',
    logo: logoAstra
  },
  {
    name: 'efishery',
    logo: logoEfishery
  },
  {
    name: 'Kitabisa.com',
    logo: logoKitabisa
  },
  {
    name: 'Halodoc',
    logo: logoHalodoc
  },
  {
    name: 'IDN Times',
    logo: logoIdnTimes
  },
  {
    name: 'Dentsu',
    logo: logoDentsu
  }
])

// ----------------------------------------------------------------------

/**
 * @returns {JSX.Element}
 */
export default function HomeCompany() {
  return (
    <div>
      <Section>
        <Typography
          component='h2'
          variant='hx'
          marginBottom={2}
          textAlign='center'
        >
          Bersama Experts dan Case Study dari Notable Companies
        </Typography>

        <Grid
          container
          spacing={2}
          alignItems='center'
          justifyContent='center'
        >
          {COMPANY_LOGO.map(({ logo, name }) => (
            <Grid
              key={`company-${name}`}
              item
              xs='auto'
            >
              <NextImage
                src={logo}
                alt={name}
                nextHeight={24}
                sx={{ objectFit: 'contain', height: '1.5rem', width: 'auto' }}
              />
            </Grid>
          ))}
        </Grid>
      </Section>
    </div>
  )
}
